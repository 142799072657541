@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --bg-colour: #081b29; /* #081b29 */
  --second-bg-colour: #112e42;
  --text-colour: #ededed;
  --main-color: #00abf0;
}

body {
  background: var(--bg-colour);
  color: var(--text-colour);
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  border: none;
  outline: none;
  scroll-behavior: smooth;
  font-family: 'Poppins', 'sans-serif';
}

.heading {
  font-size: 5rem;
  margin-bottom: 3rem;
  text-align: center;
}

span {
  color: var(--main-color);
}
