.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 2rem 9%;
  background: var(--bg-colour);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
}

.logo {
  font-size: 2.5rem;
  color: var(--text-colour);
  font-weight: 600;
}

.navbar a {
  font-size: 1.7rem;
  color: var(--text-colour);
  font-weight: 500;
  margin-left: 3.5rem;
}

.navbar a:hover,
.navbar a:active {
  color: var(--main-color);
}

#menu-icon {
  display: none;
}


/* Small phones: up to 600px
Phones: 600px - 768px
Tablets in portrait mode: 768px - 992px
Tablets in landscape mode / small desktops: 992px - 1200px
Desktops: 1200px and above */

@media (max-width: 768px) {
    /* Styles for phones */

    /* Adjust font size for better readability on smaller screens */
    /* Adjust layout or positioning for better mobile experience */
    /* Hide or adjust certain elements for better mobile layout */

    .header {
      background: var(--bg-colour);
    }
    
    #menu-icon {
      display: block;
      height: 4rem;
      width: 4rem;
    }
  
    .navbar {
      position: absolute;
      top: 100%;
      left: -100%;
      width: 100%;
      padding: 1rem 4%;
      background: var(--bg-colour);
      box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .2);
      transition: .25s ease;
      transition-delay: .25s;
    }

    .navbar.active {
        left: 0;
        transition-delay: 0s;
    }
  
    .navbar a {
      display: block;
      font-size: 2rem;
      margin: 3rem 0;
    }
  }