section {
    min-height: 100vh;
    padding: 10rem 9% 2rem;
}

.home {
    display: flex;
    align-items: center;
    padding: 0 9%;
    background: url('../assets/images/brain.jpg') no-repeat;
    background-size: cover;
    background-position: center left;
}

.home-content {
    max-width: 68rem;
}

.home-content h1 {
    font-size: 5.6rem;
    font-weight: 700;
    line-height: 1.3;
}

.home-content .text-animate {
    position: relative;
}

.home-content .text-animate h3 {
    display: inline-block;
    font-size: 3.2rem;
    font-weight: 700;
    color: transparent;
    -webkit-text-stroke: .7px var(--main-color);
    background-image: linear-gradient(var(--main-color), var(--main-color));
    background-repeat: no-repeat;
    -webkit-background-clip: text;
    background-position: 0 0;
    animation: homeBgText 6s linear infinite;
    animation-delay: 2s;
}

.home-content .text-animate h3::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    border-right: 2px solid var(--main-color);
    z-index: 1;
    animation: homeCursorText 6s linear infinite;
    animation-delay: 2s;
}

.home-content p {
    font-size: 1.6rem;
    margin: 2rem 0 4rem;
    text-align: justify;
    text-justify: inter-character;
}

.btn-box {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 50rem;
    height: 5rem;
}

.btn-box .btn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22rem;
    height: 100%;
    background-color: var(--main-color);
    border: .2rem solid var(--main-color);
    border-radius: .8rem;
    font-size: 1.8rem;
    font-weight: 600;
    letter-spacing: .1rem;
    color: var(--bg-colour);
    z-index: 1;
    overflow: hidden;
    transition: .5s;
}

.btn-box .btn:hover {
    color: var(--main-color);
}

.btn-box .btn:nth-child(2) {
    background: transparent;
    color: var(--main-color);
}

.btn-box .btn:nth-child(2):hover {
    color: var(--bg-colour);
}

.btn-box .btn:nth-child(2)::before {
    background: var(--main-color);
}

.btn-box .btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: var(--bg-colour);
    z-index: -1;
    transition: 0.5s;
}

.btn-box .btn:hover::before {
    width: 100%;
}


@keyframes homeBgText {
    0%,
    10%,
    100% {
        background-position: -45rem 0;
    }

    65%,
    85% {
        background-position: 0 0;
    }
    }

    @keyframes homeCursorText {
    0%,
    10%,
    100% {
        width: 0;
    }

    65%,
    78%,
    85% {
        width: 45rem;
        opacity: 1;
    }

    75%,
    81% {
        opacity: 0;
    }
}

@media (max-width: 1600px) {
    html {
        font-size: 55%;
    }
}

@media (max-width: 991px) {
    .header {
        padding: 2rem 4%;
    }

    section {
        padding: 10rem 4% 2rem;
    }

    .home {
        padding: 0 4%;
    }
}

@media (max-width: 768px) {
    .home {
        background-position: left;
    }

    .home-content {
        width: 100vw;
        border: 5rem;
        /* border: 0.2rem solid red; */
    }

    .home-content h1 {
        font-size: 4rem;
        font-weight: 700;
        line-height: 1.3;
    }

    .home-content .text-animate h3 {
        font-size: 2.4rem;
        font-weight: 700;
    }

    .home-content p {
        font-size: 1.4rem;
    }

    .btn-box {
        width: 100%;
        display: flex;
        justify-content: space-around;
    }

    .btn-box .btn {
        width: 45%;
       
    }

    @keyframes homeBgText {
        0%,
        10%,
        100% {
            background-position: -34rem 0;
        }
    
        65%,
        85% {
            background-position: 0 0;
        }
        }
    
        @keyframes homeCursorText {
        0%,
        10%,
        100% {
            width: 0;
        }
    
        65%,
        78%,
        85% {
            width: 34rem;
            opacity: 1;
        }
    
        75%,
        81% {
            opacity: 0;
        }
    }
}

@media (max-width: 460px) {
    .home {
        background-position: left;
    }

    .home-content {
        width: 100vw;
        border: 5rem;
        /* border: 0.2rem solid red; */
    }

    .home-content h1 {
        font-size: 3rem;
        font-weight: 700;
        line-height: 1.3;
    }

    .home-content .text-animate h3 {
        font-size: 2rem;
        font-weight: 700;
    }

    .home-content p {
        font-size: 1.4rem;
    }

    .btn-box {
        width: 100%;
        display: flex;
        justify-content: space-around;
    }

    .btn-box .btn {
        width: 45%;
        font-size: 1.3rem;
    }

    @keyframes homeBgText {
        0%,
        10%,
        100% {
            background-position: -28rem 0;
        }
    
        65%,
        85% {
            background-position: 0 0;
        }
        }
    
        @keyframes homeCursorText {
        0%,
        10%,
        100% {
            width: 0;
        }
    
        65%,
        78%,
        85% {
            width: 28rem;
            opacity: 1;
        }
    
        75%,
        81% {
            opacity: 0;
        }
    }
}

/* .home {
    display: flex;
    align-items: center;
    padding: 0 9%;
    background: url('../assets/images/brain.jpg') no-repeat;
    background-size: cover;
    background-position: center;
} */